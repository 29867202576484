
export enum VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS {
  OH = 'OH',
  OP = 'OP',
  MB = 'MB',
  S = 'S',
  L = 'L',
  DS = 'DS',
  SS = 'SS',
}

export enum VOLLEYBALL_PLAYER_POSITIONS {
  OUTSIDE_HITTER = 'outside hitter',
  OPPOSITE_HITTER = 'opposite hitter',
  MIDDLE_BLOCKER = 'middle blocker',
  SETTER = 'setter',
  LIBERO = 'libero',
  DEFENSIVE_SPECIALIST = 'defensive specialist',
  SERVE_SPECIALIST = 'serve specialist',
}

export const VOLLEYBALL_POSITION_TO_ABBREVIATION: Record<VOLLEYBALL_PLAYER_POSITIONS, VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS> = {
  [VOLLEYBALL_PLAYER_POSITIONS.OUTSIDE_HITTER]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.OH,
  [VOLLEYBALL_PLAYER_POSITIONS.OPPOSITE_HITTER]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.OP,
  [VOLLEYBALL_PLAYER_POSITIONS.MIDDLE_BLOCKER]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.MB,
  [VOLLEYBALL_PLAYER_POSITIONS.SETTER]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.S,
  [VOLLEYBALL_PLAYER_POSITIONS.LIBERO]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.L,
  [VOLLEYBALL_PLAYER_POSITIONS.DEFENSIVE_SPECIALIST]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.DS,
  [VOLLEYBALL_PLAYER_POSITIONS.SERVE_SPECIALIST]: VOLLEYBALL_PLAYER_ABBREVIATED_POSITIONS.SS,
}
