
export enum PLAYER_ACTIONS {
  REMOVE_PLAYER = 'PLAYER.REMOVE_PLAYER',
  ADD_PLAYER = 'PLAYER.ADD_PLAYER',
  MODIFY_PLAYER = 'PLAYER.MODIFY_PLAYER',
}

export enum ROSTER_ACTIONS {
  ADD_PLAYER = 'ROSTER.ADD_PLAYER',
  REMOVE_PLAYER = 'ROSTER.REMOVE_PLAYER',
  MOVE_PLAYER = 'ROSTER.MOVE_PLAYER',
}
